import React from 'react';
import './Information.css';
import videoImg from '../../assets/images/video.png';
import Vimeo from '@u-wave/react-vimeo';
import benchIcon from '../../assets/images/benchIcon.png';
import { withRouter } from 'react-router';
import lang from '../Language/language.js';

export class Information extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItemId: null,
            showPopUp: null,
            playVideo: false,
            paused: false,
            likaExplainMore: false,
            spannExplain: false,
        }
    }

    videoImport = () => {
        this.setState({
            paused: false,
            playVideo: true
        })
    }
    stopAndHideVideo = () => {
        this.setState({
            paused: true,
            playVideo: false
        })
    }
    likeExplain = () => {
        if (this.state.likaExplainMore) {
            this.setState({
                likaExplainMore: false
            })
        } else {
            this.setState({
                likaExplainMore: true
            })
        }
    }
    spannExplain = () => {
        if (this.state.spannExplain) {
            this.setState({
                spannExplain: false
            })
        } else {
            this.setState({
                spannExplain: true
            })
        }
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let settingEU = localStorage.getItem('settingEU');
        if (this.props.step === "arbetsvardera") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Arbetsvärdera" : lang[language].infoJobEval}</h1>
                    <div className="explainer-box" style={{ marginBottom: 0 }}>
                        <div className="explainer-text">
                            {language === "sv" ? `Arbetsvärdering innebär en värdering av arbetets krav. Denna används för analys av likvärdiga arbeten i steg 3.2.
                            Totalt finner ni 8 områden där ni för varje arbete ska sätta en poäng på 1-4.
                            Klicka på info symbolen vid respektive område för att se vad bedömningen baseras på och vad ni bör ha i beaktande.

                            Notera att ni även kan vikta områden som är extra relevanta för er organisation. Klicka på videon för mer information.` : lang[language].infoJobEvalDetails}
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="584391059"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="explainer-box" style={{ paddingTop: 0 }}>
                        <div className="explainer-text" style={{ width: "fit-content", width: "unset" }}>
                            <p style={{ border: "1px solid grey", borderRadius: 5, padding: 5 }}><b>1</b> = {language === "sv" ? "låga krav" : lang[language].infoLowReq} | <b>2</b> = {language === "sv" ? "medelhöga krav" : lang[language].infoMedReq} |  <b>3</b> = {language === "sv" ? "höga krav" : lang[language].infoHighReq} |  <b>4</b> = {language === "sv" ? "mycket höga krav" : lang[language].infoHighestReq}</p>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "arbetsvarderaEdited") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Arbetsvärdera" : lang[language].infoJobEval}</h1>
                    <div className="explainer-box" style={{ marginBottom: 0 }}>
                        <div className="explainer-text">
                            {language === "sv" ? `Arbetsvärdering innebär en värdering av arbetets krav. Denna används för analys av likvärdiga arbeten i steg 3.2. 
                        Nedan finner ni ett antal områden där ni för varje arbete ska sätta en poäng på 1-4 (förutsatt att ni inte har ändrat antal nivåer). 
                        Ju högre poäng desto högre krav. Klicka på info symbolen vid respektive område för att se vad bedömningen baseras på och vad ni bör 
                        ha i beaktande. Notera att ni även kan vikta områden som är extra relevanta för er organisation. Klicka på videon för mer information. 
                        Förutsatt att ni inte har ändrat antal nivåer gäller nedan:` : lang[language].infoJobEvalDetailsEdited}
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="584391059"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="explainer-box" style={{ paddingTop: 0 }}>
                        <div className="explainer-text" style={{ width: "fit-content", width: "unset" }}>
                            <p style={{ border: "1px solid grey", borderRadius: 5, padding: 5 }}><b>1</b> = {language === "sv" ? "låga krav" : lang[language].infoLowReq} | <b>2</b> = {language === "sv" ? "medelhöga krav" : lang[language].infoMedReq} |  <b>3</b> = {language === "sv" ? "höga krav" : lang[language].infoHighReq} |  <b>4</b> = {language === "sv" ? "mycket höga krav" : lang[language].infoHighestReq}</p>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "arbetsvarderaExt") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Arbetsvärdera" : lang[language].infoJobEval}</h1>
                    <div className="explainer-box">
                        <div className="explainer-text">
                            {language === "sv" ? "Detta avsnitt är för er som redan har en arbetsvärdering. Om ni istället vill skapa en ny värdering i Lönelys går ni in och väljer detta under inställningar." : lang[language].infoJobEvalDetailsExtern}
                            <p>{language === "sv" ? `För att använda befintlig värdering anger ni först namnet på systemet som använts. Ange därefter vilken nivå varje arbete har hamnat i baserat på ert resultat. Nivåerna anges som en siffra i hierarkisk ordning, där arbeten med samma nivå har samma krav. En etta innebär lägst krav, en tvåa har högre krav osv.
                      När alla arbeten är indelade i en nivå är ni klara.` : lang[language].infoJobEvalDetailsExternTwo}
                            </p>

                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="832936378"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "import") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Importera lönedata" : lang[language].infoImportTitle}</h1>
                    <div className="explainer-box" style={{ marginBottom: 0 }}>
                        <div className="explainer-text">
                            {language === "sv" ? ` Börja med att importera personaldata. Denna får ni ut genom ert lönesystem. För att importen ska bli korrekt är det viktigt att datan är strukturerad på rätt sätt. Ladda därför ner vår mall och fyll den med er information, för att sedan importera den. Säkerställ att filen slutar med ".xlsx".` : lang[language].infoImportDetails}
                            <p style={{ marginBlockEnd: 0 }}>{language === "sv" ? "Vid nytt år eller för justeringar så laddar ni bara upp en ny fil. Denna ersätter den gamla. Ni rekommenderas därför att varje år spara er importfil, så att ni enkelt kan återskapa gammal personaldata vid behov." : lang[language].infoImportDetailsTwo}</p>
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper" >
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="584487258"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="explainer-box">
                        <div className="explainer-text">
                            {this.props.benchActive && settingEU !== "on" ? <a href='./lonelys-import-file.xlsx' className="linkDownload" download style={{ marginTop: 5, width: 140 }}>{language === "sv" ? "Ladda ner mall" : lang[language].infoImportDownload}</a>
                                : !this.props.benchActive && settingEU !== "on" ? <a href='./import-file-lonelys.xlsx' className="linkDownload" download style={{ marginTop: 5, width: 140 }}>{language === "sv" ? "Ladda ner mall" : lang[language].infoImportDownload}</a>
                                : <a href='./lonelys-import.xlsx' className="linkDownload" download style={{ marginTop: 5, width: 140 }}>{language === "sv" ? "Ladda ner mall" : lang[language].infoImportDownload}</a>
                            }
                        </div>
                    </div>


                </div>
            )
        }

        if (this.props.step === "grupperaLika") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Gruppera lika arbeten" : lang[language].infoGroupSimTitle}</h1>
                    <div className="explainer-box">
                        <div className="explainer-text">
                            {language === "sv" ? `Nedan finner ni alla befattningar i er organisation. Ni behöver nu gruppera vilka som kan anses utföra ungefär samma arbetsuppgifter, så kallat lika arbete. Detta gör ni genom att klicka på "Ange grupp" och ange ett namn för gruppen. Om ni har olika titlar på arbeten med ungefär samma arbetsuppgifter ska ni alltså ange en gemensam grupp för dessa. Om det finns befattningar vars arbete inte kan likställas med andra kan ni bara ange befattningens namn som grupp.` : lang[language].infoGroupSimDetails}

                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="584487372"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "grupperaLikaMarknad") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Gruppera lika arbeten" : lang[language].infoGroupSimTitle}</h1>
                    <div className="explainer-box">
                        <div className="explainer-text">
                            {language === "sv" ? `Nedan finner ni alla befattningar i er organisation. Ni behöver nu gruppera vilka som kan anses utföra ungefär samma arbetsuppgifter, så kallat lika arbete. Detta gör ni genom att klicka på "Ange grupp" och ange ett namn för gruppen. Om ni har olika titlar på arbeten med ungefär samma arbetsuppgifter ska ni alltså ange en gemensam grupp för dessa. Om det finns befattningar vars arbete inte kan likställas med andra kan ni bara ange befattningens namn som grupp.` : lang[language].infoGroupSimDetails}
                            <p>{language === "sv" ? ` Under varje befattning ser ni även en grå rad där ni kan välja statistikkod. Klicka för att söka och välj det arbete som passar bäst på befattningen. Koden används för att få fram marknadslönedata och påverkar inte er gruppering av lika arbeten.` : lang[language].infoGroupSimDetailsTwo}</p>
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="584487372"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "analysLika") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Analysera lika arbeten" : lang[language].infoAnalyseEqualTitle}</h1>
                    <div className="explainer-box" style={{ marginBottom: 0 }}>
                        <div style={{ fontSize: 14.6, padding: 15, marginTop: 0, textAlign: "left", height: "auto", maxWidth: 640, position: "absolute", letterSpacing: -0.2 }} class={this.state.likaExplainMore ? "popUp active-popUp" : "hide"}>
                            <span className="exitVideo" onClick={this.likeExplain} style={{ marginTop: 0, float: "right", textAlign: "center", cursor: "pointer" }}>x</span>
                            <span style={{ display: "inline-block", width: "97%" }}>{language === "sv" ? `I steg 4 kan ni automatiskt skapa en färdig dokumentation. Denna visar era kommentarer.
                                Om ni vill att något inte ska visas i rapporten kan ni skriva dessa delar inom parentes tecken. All text inom parentes kommer alltså att döljas i rapporten.
                                Detta är användbart för att till exempel använda namn på personer i analysen, men hålla dem anonyma i rapporten.` : lang[language].infoAnalyseEqualParan}</span>
                            <p>{language === "sv" ? "Exempel på hur ni kan använda namn i analysen men dölja i rapporten:" : lang[language].infoAnalyseEqualParanTwo}</p>
                            <div style={{ border: "1px solid #333333", borderRadius: 5, padding: 5, paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}>
                                {language === "sv" ? <p><u>Text i kommentar:</u> <em>En man <b>(Martin Stensson)</b> tjänar 5000 kr mer än en kvinna <b>(Sofia Tåström)</b> som arbetat två år längre i organisationen.</em></p> : lang[language].infoAnalyseEqualExample}
                                {language === "sv" ? <p><u>Blir i rapport:</u><em> En man tjänar 5000 kr mer än en kvinna som arbetat två år längre i organisationen.</em></p> : lang[language].infoAnalyseEqualExampleTwo}
                            </div>
                        </div>
                        <div className="explainer-text">
                            {language === "sv" ? `Analys av lika arbete innebär analys av arbeten som kan anses utföra samma typ av arbetsuppgifter (grupperades in i steg 2.1). Gå igenom varje grupp i listan till vänster och analysera löneskillnader mellan män och kvinnor. Finns det saklig orsak till eventuell löneskillnad? Punktdiagrammet hjälper er att jämföra, där axlarna visar lön i förhållande till år i organisationen. När ni hittar något som behöver analyseras, ange då detta som en kommentar under diagrammet. Ange sedan orsak och åtgärd vid behov. Se video för mer information.` : lang[language].infoAnalyseEqualDetails}
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="584556064"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="explainer-box" style={{ paddingTop: 0 }}>
                        <div className="explainer-text">
                            <p style={{
                                border: "1px solid grey",
                                borderRadius: 5,
                                padding: 5,
                                paddingTop: 3,
                                paddingBottom: 3,
                                width: "95%"
                            }}
                            >{language === "sv" ? "Notera att era kommentarer visas i rapporten (steg 4). Om ni vill dölja information i rapporten, t ex. namn, kan ni skriva det inom parentes. " : lang[language].infoAnalyseEqualComments}<span style={{
                                color: "#55cbae",
                                fontWeight: 600,
                                textDecoration: "underline",
                                cursor: "pointer"
                            }} onClick={this.likeExplain}>{language === "sv" ? "Läs mer" : lang[language].moreInfo}</span> </p>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "analysLikaMarknad") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Analysera lika arbeten" : lang[language].infoAnalyseEqualTitle}</h1>
                    <div className="explainer-box" style={{ marginBottom: 0 }}>
                        <div style={{ fontSize: 14.6, padding: 15, marginTop: 0, textAlign: "left", height: "auto", maxWidth: 640, position: "absolute", letterSpacing: -0.2 }} class={this.state.likaExplainMore ? "popUp active-popUp" : "hide"}>
                            <span className="exitVideo" onClick={this.likeExplain} style={{ marginTop: 0, float: "right", textAlign: "center", cursor: "pointer" }}>x</span>
                            <span style={{ display: "inline-block", width: "97%" }}>{language === "sv" ? `I steg 4 kan ni automatiskt skapa en färdig dokumentation. Denna visar era kommentarer.
                                Om ni vill att något inte ska visas i rapporten kan ni skriva dessa delar inom parentes tecken. All text inom parentes kommer alltså att döljas i rapporten.
                                Detta är användbart för att till exempel använda namn på personer i analysen, men hålla dem anonyma i rapporten.` : lang[language].infoAnalyseEqualParan} </span>
                            <p>{language === "sv" ? "Exempel på hur ni kan använda namn i analysen men dölja i rapporten:" : lang[language].infoAnalyseEqualParanTwo}</p>
                            <div style={{ border: "1px solid #333333", borderRadius: 5, padding: 5, paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}>
                                {language === "sv" ? <p><u>Text i kommentar:</u> <em>En man <b>(Martin Stensson)</b> tjänar 5000 kr mer än en kvinna <b>(Sofia Tåström)</b> som arbetat två år längre i organisationen.</em></p> : lang[language].infoAnalyseEqualExample}
                                {language === "sv" ? <p><u>Blir i rapport:</u><em> En man tjänar 5000 kr mer än en kvinna som arbetat två år längre i organisationen.</em></p> : lang[language].infoAnalyseEqualExampleTwo}
                            </div>
                        </div>
                        <div className="explainer-text">
                            {language === "sv" ? `Analys av lika arbete innebär analys av arbeten som kan anses utföra samma typ av arbetsuppgifter (grupperades in i steg 2.1). Gå igenom varje grupp i listan till vänster och analysera löneskillnader mellan män och kvinnor. Finns det saklig orsak till eventuell löneskillnad? Punktdiagrammet hjälper er att jämföra, där axlarna visar lön i förhållande till år i organisationen. När ni hittar något som behöver analyseras, ange då detta som en kommentar under diagrammet. Ange sedan orsak och åtgärd vid behov. Se video för mer information.` : lang[language].infoAnalyseEqualDetails}
                            <p>{language === "sv" ? `I detta steg kan ni även se marknadslöner genom att klicka på "` : lang[language].infoAnaluseEqualInStepOne}<img src={benchIcon} width={14} style={{ verticalAlign: "top", marginTop: 3 }} />{language === "sv" ? `" ikonen i diagrammet. Detta ger en inblick i hur era löner förhåller sig mot marknaden.` : lang[language].infoAnaluseEqualInStepTwo}</p>
                        </div>

                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="584556064"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="explainer-box" style={{ paddingTop: 0 }}>
                        <div className="explainer-text">
                            <p style={{
                                border: "1px solid grey",
                                borderRadius: 5,
                                padding: 5,
                                paddingTop: 3,
                                paddingBottom: 3,
                                width: "95%"
                            }}
                            >{language === "sv" ? "Notera att era kommentarer visas i rapporten (steg 4). Om ni vill dölja information i rapporten, t ex. namn, kan ni skriva det inom parentes. " : lang[language].infoAnalyseEqualComments}<span style={{
                                color: "#55cbae",
                                fontWeight: 600,
                                textDecoration: "underline",
                                cursor: "pointer"
                            }} onClick={this.likeExplain}>{language === "sv" ? "Läs mer" : lang[language].moreInfo}</span> </p>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "analysLikvardiga") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Analysera likvärdiga arbeten & hierarkiska skillnader" : lang[language].infoAnalyseEqTitle}</h1>
                    <div className="explainer-box">
                        <div className="explainer-text">
                            {language === "sv" ? "Analys av likvärdiga arbeten innebär analys av arbeten som har likvärdiga krav. Nedan finner ni flera grupper. Ju högre grupp desto högre krav i arbetet. Vilken grupp arbetet har hamnat i baseras på den arbetsvärdering ni gjorde i steg 2.2. Analysera nu arbetena inom varje grupp. Finns skillnader mellan kvinnodominerade arbeten och övriga? Anteckna ner skillnader." : lang[language].infoAnalyseEqDetailsOne}
                            <p></p>
                            {language === "sv" ? "Analys av hierarkiska skillnader innebär att ni analyserar löneskillnader i arbeten mellan grupperna, finns det kvinnodominerade arbeten som har högre krav men lägre lön? Se video för mer information och tips om hur ni gör er analys." : lang[language].infoAnalyseEqDetailsTwo}
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="584487657"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "dokumentera") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Skapa dokumentation" : lang[language].infoReportTitle}</h1>
                    <div className="explainer-box">
                        <div className="explainer-text">
                            {language === "sv" ? `Enligt lag måste ni varje år dokumentera er lönekartläggning.
                            Detta görs enkelt med ett knapptryck.
                            All data sammanställs automatiskt, men innan ni skapar dokumentationen behöver ni fylla i relevant information nedan. När alla fält är ifyllda klickar ni på "Skapa PDF/Wordfil", därefter är ni klara med er lönekartläggning! Dokumentationen behöver inte skickas in någonstans utan sparas på lämplig plats.
                            Notera att ni även bör spara importfilen (excelfilen) så att ni i framtiden enkelt kan återskapa data för tidigare år vid behov.
                           ` : lang[language].infoReportDetails}
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="584487462"
                                        //video="917100678" // Test video
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "foraldraledighet") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Analysera föräldraledighet" : lang[language].infoReportTitle}</h1>
                    <div className="explainer-box">
                        <div className="explainer-text">
                            {language === "sv" ? `Från och med juni 2026 måste alla arbetsgivare analysera löneutveckling och föräldraledighet (här inkluderas även ledighet för vård av närstående samt ledighet av trängande familjeskäl). 
                           ` : lang[language].infoReportDetails}
                           <div>Analysen sker inom likvärdiga arbeten och det som ska analyseras är:</div>
                           <ul style={{listStyleType: "disc", paddingTop: 10, paddingLeft: 30}}>
                            <li style={{marginBottom: 5}}>Löneutvecklingen för medarbetare som varit föräldralediga oavsett kön jämte de som inte har varit föräldralediga</li>
                            <li>Löneutvecklingen för kvinnor och män som har varit föräldralediga.</li>
                           </ul>
                           <p style={{ background: "#f3f3f3", borderRadius: 5, padding: 10, marginTop: 30 }}>
                                <b>OBS</b> | Avsnittet är i ett beta stadie. Det innebär att utvärdering och utveckling fortfarande pågår och vi tar gärna emot förslag och synpunkter.
                            </p>
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="584487462"
                                        //video="917100678" // Test video
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "utvardera") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Utvärdera föregående år" : lang[language].infoEvaluateTitle}</h1>
                    <div className="explainer-box">
                        <div className="explainer-text">
                            {language === "sv" ? `Från och med år 2 och framåt behöver ni utvärdera de åtgärder ni kom fram till föregående år.
                            Nedan finner ni en lista på dessa åtgärder. Ange nedan om de är utförda eller ej genom att klicka på "x" ikonen. Om en åtgärd inte är utförd behöver ni
                            lägga till en kommentar om varför.` : lang[language].infoEvaluateDetailsOne}
                            <p>
                                {language === "sv" ? `En översikt över nuvarande års åtgärder kan också visas och här kan ni närsomhelst gå in och klarmarkera åtgärder, alternativt gör ni det vid nästa års utvärdering. Ni kan här även lägga till nya övergripande åtgärder vid behov.` : lang[language].infoEvaluateDetailsTwo}
                            </p>
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="813894857"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "rapportera") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Rapportering" : lang[language].overviewOrg}</h1>
                    <div className="explainer-box">
                        <div className="explainer-text">
                            Företag med 100 anställda eller fler ska skicka in en lönerapport till DO. Om över 250 anställda ska rapporten skickas in varje år, om 100 - 249 anställda skickas den in var tredje år.
                            <p>
                           Denna lönerapport är separerad från lönekartläggningen och kräver därför en egen import, undantaget "löneskillnad per arbetstagarkategori" som hämtas direkt från lönekartläggningen. Ladda ner vår mall och importera den sedan här nedan när den är ifylld. 
                           Därefter visas all data ni behöver till er rapport.
                        Se video för mer information.
                                     </p>
                                     <div className="explainer-text">
                                     <a href='./lonelys-reporting.xlsx' className="linkDownload" download style={{ marginTop: 5, width: 140 }}>{language === "sv" ? "Ladda ner mall" : lang[language].infoImportDownload}</a>
                        </div>
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    <Vimeo
                                        video="813894857"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
                                    />
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.step === "lonestruktur") {
            return (
                <div className="explainer-container">
                    <h1>{language === "sv" ? "Jobbarkitektur & lönestruktur" : lang[language].jobArcAndSalaryStructure}</h1>
                    <div className="explainer-box" style={{ marginBottom: 0 }}>
                        <div style={{ fontSize: 14.6, padding: 15, marginTop: 0, textAlign: "left", height: "auto", maxWidth: 640, position: "absolute", letterSpacing: -0.2 }} class={this.state.likaExplainMore ? "popUp active-popUp" : "hide"}>
                            <span className="exitVideo" onClick={this.likeExplain} style={{ marginTop: 0, float: "right", textAlign: "center", cursor: "pointer" }}>x</span>
                            <span style={{ display: "block", width: "97%",paddingBottom: 40, }}>
                                <b style={{position: "absolute",fontSize: 20,marginTop: 10}}>Karriärsvägar</b>
                            </span>
                            <p style={{lineHeight: 1.5}}>Arbetsvärderingen som ni utförde under steg 2.2 har nu delat in era arbeten i olika nivåer. Ju högre nivå desto högre krav i arbetet.
                                Dessa nivåer kan ses som en grov karriärsstege, där anställda kan se vilka möjligheter för avancemang till nya roller som finns inom företaget.
                            </p>
                            <p style={{lineHeight: 1.5}}>För att göra denna karriärsstege tydligare kan ni dela in era arbeten i jobbfamiljer och vid behov underkategorier. Detta ger en tydlig och visuell beskrivning för hur alla arbeten förhåller sig till varandra och vilka karriärsvägar som finns inom respektive område/jobbfamilj.
                            </p>
                            <p style={{lineHeight: 1.5}}>Nedan har systemet automatiskt delat in era arbeten och tagit fram ett förslag baserat på de statistikkoder (ssyk eller nyk) som ni angett vid excelimporten eller under steg 2.1.</p>
                            <p style={{lineHeight: 1.5}}>Ni måste inte följa förslagen utan är fria att dela in era arbeten hur ni vill.</p>
                        </div>

                        <div style={{lineHeight: 1.5, fontSize: 14.6, padding: 15, marginTop: 0, textAlign: "left", height: "auto", maxWidth: 640, position: "absolute", letterSpacing: -0.2 }} class={this.state.spannExplain ? "popUp active-popUp" : "hide"}>
                            <span className="exitVideo" onClick={this.spannExplain} style={{ marginTop: 0, float: "right", textAlign: "center", cursor: "pointer" }}>x</span>
                            <span style={{ display: "block", width: "97%",paddingBottom: 40, }}>
                                <b style={{position: "absolute",fontSize: 20,marginTop: 10}}>Lönespann</b>
                            </span>
                            <p>Lönespann kan hjälpa er att skapa en tydlig och transparent lönestruktur.</p>
                        {/* Ev ta bort nedan text: */}
                        <p>Ni kan välja att inte använda lönespann om ni på annat sätt fastställer arbetstagarnas löner, lönenivåer och löneutveckling. Det är då viktigt att kriterierna för hur ni fastställer detta är sakliga och könsneutrala, och ni ska kunna ge ut dessa kriterier till arbetstagare och fackförbund på förfrågan.</p>

                        <p>Lönespann kan anges på något av följande sätt: </p>
                        <ul style={{listStyleType: "square"}}>
                            <li>Övergripande per nivå/likavärdesgrupp</li>
                            <li>Per jobbfamilj (om ni angett karriärsvägar)</li>
                            <li>Per lika arbete</li>
                            <li>En kombination av ovan (ni kan tex sätta övergripande per nivå men sätta specifika lönenivåer för arbeten som tex går utanför pga marknadslönerna)</li>
                            <li>Ev även kunna dela upp lönespannen, dvs "spann i spannet" baserat på junior, senior etc. Detta är typ redan med då man sätter midpoint tror jag</li>
                            <li>Ev ort hade kunnat vara relevant med, men kanske skippa till en början iaf.</li>
                        </ul>
                        <p>För att snabba upp processen har Lönelys automatiskt tagit fram färdiga förslag för er baserat på er nuvarande data samt marknadslöner. Ni bör dock se över så att dessa stämmer med er lönepolicy och ni kan ändra helt hur ni vill.</p>
                        <p>Om ni inte har lönespann sedan tidigare kan ni göra på nedan sätt för att manuellt kontrollera lönespann: </p>
                        <ul style={{listStyleType: "square"}}>
                            <li>Se över nuvarande löner, de bör ligga inom spannet ni anger.</li>
                            <li>Se över marknadslöner, hur vill ni förhålla er till detta?</li>
                            <li>OBS: Om ni har breda grupper, såsom "Marknadsförare", så kan det vara en god idé att dela upp dessa i tex "junior marknadsförare", "senior" etc. Ändra då titeln i er excelfil <b>OBS!!! DETTA BLIR INTE BRA. DÅ HAR MAN REDAN GJORT ARBETSVÄRDERING OCH LIKA GRUPPERING OCH MÅSTE GÖRA OM. FÅR FUNDERA HÄR, KANSKE KAN PÅMINNA TIDIGARE, TEX UNDER LIKA GRUPPERING. -- HMM! EN LÖSNING KAN VARA ATT SKAPA FLERA "NIVÅER" I LÖNESPANNET. TEX ETT SPANN FÖR JUNIOR, ETT FÖR SENIOR ETC.. Så spann i spann. Detta görs typ redan genom att använda midpoint</b></li>
                        </ul>

                        <p>Att göra för min del nu: ------------</p>
                        <ul style={{listStyleType: "square"}}>
                        <li>Systemet ska automatiskt ta fram färdiga förslag. Man kan gå igenom varje arbete om man vill med ett filter. Någonstans bör det stå vilka som fått ett spann.</li>
                        <li>Visualisera det hela på ett bra och överskådligt sätt. HUR GÖR JAG DETTA FÖR LIKA ARBETEN? Total översikt och markering vilka som har godkänt spann.</li>
                       <li>Man ska kunna ange "Kollektiv", för arbeten där man följer kollektivavtalet. Men ofta har man tjänstemän som ska täckas av detta.</li>
                      <li>Tänk på att när man sätter för lika arbeten bör det tas hänsyn till nivån. Dvs man kan inte bara ta hänsyn till marknaden.</li>
                       <li>Oavsett om man har valt per arbete eller per nivå så kan jag ge datan som behövs till EU direktivet genom att ange det totala spannet för alla arbeten inom en likvärdig grupp. Detta är också det som visas om jag anger per arbete antar jag.</li>
                        <li>Lönespann ska visas i lika och likvärdiga analyserna sen</li>
                        </ul>

                        </div>

                        <div className="explainer-text">
                        {language === "sv" ? "Med en jobbarkitektur kan ni skapa transparenta lönestrukturer och tydliggöra lönespann för era roller i organisationen." : lang[language].arcInfoOne}
                            <p>{language === "sv" ? "Om ni redan på annat sätt har tydliga och transparenta lönestrukturer behöver ni ej använda jobbarkitekturen i Lönelys." : lang[language].arcInfoTwo}</p>
                            <p>{language === "sv" ? "Här kan ni läsa mer info om lönespann och karriärsvägar:" : lang[language].arcInfoThree}</p>
                            <div onClick={this.likeExplain} style={{ color: "#51c2a5", fontWeight: 600, textDecoration: "underline", cursor: "pointer", display: "inline-block", marginRight: 5 }}>Karriärsvägar</div> <span style={{ color: "#50c2a5" }}>|</span> <div onClick={this.spannExplain} style={{ color: "#51c2a5", fontWeight: 600, textDecoration: "underline", cursor: "pointer", display: "inline-block", marginLeft: 5 }}>Lönespann</div>
                        </div>
                        <div className="video-explainer">
                            <div className="video-wrapper">
                                <img src={videoImg} alt="instruktionsvideo" className="video" onClick={this.videoImport} />
                                <div className={this.state.playVideo ? "videoContainer" : "hideVideo"}>
                                    {/* <Vimeo
                                        video="584556064"
                                        paused={this.state.paused}
                                        autoplay={false}
                                        height="300px"
                                        showTitle={false}
                                        color="55cab1"
                                        showByline={false}
                                        showPortrait={false}
            /> */}
                                    <span className="exitVideo" onClick={this.stopAndHideVideo}>x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="explainer-box" style={{ paddingTop: 0 }}>
                        <div className="explainer-text">
                            <p style={{ background: "#f3f3f3", borderRadius: 5, padding: 10, marginTop: 30 }}>
                                <b>OBS</b> | {language === "sv" ? "Avsnittet är i ett beta stadie. Det innebär att utvärdering och tester fortfarande utförs och vi tar gärna emot förslag om ni saknar någon funktion eller liknande." : lang[language].betaInfo}
                            </p>
                        </div>
                    </div>
                </div>
            )
        }

    }

} /* End of class */